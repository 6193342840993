const axios = window.axios
const url = '/api/v2/sales-channels/leisure/'




export const LeisureSourceApiUrls = {
    // leisure tariffs
    async getSources(params){
        return (await axios.get(`${url}`, { params: params })).data;
    },
    async createSource(body){
        return (await axios.post(`${url}`, body)).data;
    },
    async getSource(id){
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setSource(id, body){
        return (await axios.put(`${url}${id}/`, body)).data;
    },
    async deleteSource(id){
        return (await axios.delete(`${url}${id}/`)).data;
    },
}