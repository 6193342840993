<template>
    <v-container>
        <v-card class="px-5 py-3">
            <v-data-table
                :headers="headers"
                :items="items"
                :single-select="true"
                :items-per-page="5"
                loading-text="Идёт загрузка данных..."
                item-key="id"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalItems"
            >
                <template v-slot:[`item.commission_type`]="{ item }">
                    {{ item.commission_type == 'percent' ? 'Процент' : 'Фиксированный' }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row align="center">
                        <v-btn icon @click="editSource(item.id)">
                            <v-icon small>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn icon @click="deleteSource(item.id)">
                            <v-icon small color="red darken-2">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </v-row>
                </template>
            </v-data-table>
            <v-btn color="primary" class="ma-3" @click="addSource">
                Добавить источник продаж
            </v-btn>
        </v-card>

        <v-dialog v-model="dialog" max-width="600">
            <v-card class="pa-5">
                <v-card-title
                    class="font-weight-bold text-left"
                    style="font-size: 24px; padding: 0"
                    >{{ isEditing ? 'Изменение источника' : 'Добавление источника' }}
                </v-card-title>
                <v-row class="py-5">
                    <v-col cols="12" md="6">
                        <v-text-field dense v-model="modelItem.title" label="Название"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field dense v-model="modelItem.commission" label="Комиссия"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select dense :items="typeItems" v-model="modelItem.commission_type" label="Тип источника"></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select dense :items="channelItems" v-model="modelItem.channel_type" label="Тип источника"></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-btn @click="save" color="warning" :disabled="disabledBtn">{{ isEditing ? 'Изменить' : 'Создать' }}</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
import { LeisureSourceApiUrls } from "@/services/leisureRequests/source.api.js"
import toast from "../../../plugins/toast";
import moment from "moment";

export default {
    data(){
        return{
            firstWatcherTrigger: false,
            disabledBtn: false, 
            dialog: false, 
            isEditing: false, 
            modelItem: {},
            totalItems: 0,
            options: {},
            loading: true,
            typeItems: [
                {
                    text: 'Процент', 
                    value: 'percent'
                },
                {
                    text: 'Фиксированный', 
                    value: 'fixed'
                },
            ], 
            channelItems: [
                {
                    text: 'Go2trip', 
                    value: 'go2trip'
                },
                {
                    text: 'Easybook', 
                    value: 'easybook'
                },
            ],
            headers: [
                { text: "Название", value: "title", class: "table-title" },
                {
                    text: "Вид комиссии",
                    value: "commission_type",
                    class: "table-title"
                },
                { text: "Дата", value: "created_at", class: "table-title" },
                {
                    text: "Комиссия",
                    value: "commission",
                    class: "table-title"
                },
                {
                    text: "Действия",
                    value: "actions",
                    class: "table-title"
                }
            ],
            items: []
        }
    },
    watch: {
        options: {
            handler() {
                if (!this.firstWatcherTrigger) {
                    this.firstWatcherTrigger = true;
                    return;
                }
                this.loadRow();
            },
            deep: true
        },
        dialog(){
            if(!this.dialog) this.isEditing = false
        }
    },
    created(){
        this.loadRow()
    },
    methods: {
        async loadRow(){
            this.loading = true
            let params = {
                "page[number]": this.options.page || 1,
                "page[size]": this.options.itemsPerPage || 10
            };
            try{
                let res = await LeisureSourceApiUrls.getSources(params)
                this.totalItems = res.meta.pagination.count;
                res.results.forEach(e => {
                    e.created_at = moment(e.created_at * 1000).format("DD.MM.YYYY")
                })
                this.items = res.results
            }
            finally{
                this.loading = false
            }
        },
        async editSource(id){
            let res = await LeisureSourceApiUrls.getSource(id)
            this.modelItem = { ...res }
            this.isEditing = true
            this.dialog = true
        },
        async deleteSource(id){
            await LeisureSourceApiUrls.deleteSource(id)
            this.items = [];
            toast.success("Успешно удалено!")
            this.loadRow();
        },
        addSource(){
            this.isEditing = false
            this.modelItem = {}
            this.dialog = true
        },
        async save(){
            this.disabledBtn = true
            try{
                if(this.isEditing){
                    await LeisureSourceApiUrls.setSource(this.modelItem.id, this.modelItem)
                    toast.success("Успешно изменилось!")
                    this.loadRow()
                    this.dialog = false
                    this.modelItem = {}
                    this.isEditing = false
                }
                else{
                    await LeisureSourceApiUrls.createSource(this.modelItem)
                    toast.success("Успешно создано!")
                    this.loadRow()
                    this.dialog = false
                    this.modelItem = {}
                    this.isEditing = false
                }
            }
            finally{
                this.disabledBtn = false
            }
        }
    }
}
</script>


<style lang="scss">
    
</style>